exports.components = {
  "component---src-account-pages-logout-js": () => import("./../../../src/account/pages/logout.js" /* webpackChunkName: "component---src-account-pages-logout-js" */),
  "component---src-page-templates-category-article-js": () => import("./../../../src/pageTemplates/category/Article.js" /* webpackChunkName: "component---src-page-templates-category-article-js" */),
  "component---src-page-templates-category-product-js": () => import("./../../../src/pageTemplates/category/Product.js" /* webpackChunkName: "component---src-page-templates-category-product-js" */),
  "component---src-page-templates-single-account-page-js": () => import("./../../../src/pageTemplates/single/AccountPage.js" /* webpackChunkName: "component---src-page-templates-single-account-page-js" */),
  "component---src-page-templates-single-article-js": () => import("./../../../src/pageTemplates/single/Article.js" /* webpackChunkName: "component---src-page-templates-single-article-js" */),
  "component---src-page-templates-single-location-js": () => import("./../../../src/pageTemplates/single/Location.js" /* webpackChunkName: "component---src-page-templates-single-location-js" */),
  "component---src-page-templates-single-page-js": () => import("./../../../src/pageTemplates/single/Page.js" /* webpackChunkName: "component---src-page-templates-single-page-js" */),
  "component---src-page-templates-single-product-js": () => import("./../../../src/pageTemplates/single/Product.js" /* webpackChunkName: "component---src-page-templates-single-product-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blocks-js": () => import("./../../../src/pages/blocks.js" /* webpackChunkName: "component---src-pages-blocks-js" */),
  "component---src-pages-cart-js": () => import("./../../../src/pages/cart.js" /* webpackChunkName: "component---src-pages-cart-js" */),
  "component---src-pages-checkout-js": () => import("./../../../src/pages/checkout.js" /* webpackChunkName: "component---src-pages-checkout-js" */),
  "component---src-pages-search-js": () => import("./../../../src/pages/search.js" /* webpackChunkName: "component---src-pages-search-js" */),
  "slice---src-slices-bottom-footer-navigation-js": () => import("./../../../src/slices/BottomFooterNavigation.js" /* webpackChunkName: "slice---src-slices-bottom-footer-navigation-js" */),
  "slice---src-slices-footer-navigation-js": () => import("./../../../src/slices/FooterNavigation.js" /* webpackChunkName: "slice---src-slices-footer-navigation-js" */),
  "slice---src-slices-main-navigation-js": () => import("./../../../src/slices/MainNavigation.js" /* webpackChunkName: "slice---src-slices-main-navigation-js" */),
  "slice---src-slices-reusable-block-js": () => import("./../../../src/slices/ReusableBlock.js" /* webpackChunkName: "slice---src-slices-reusable-block-js" */),
  "slice---src-slices-top-navigation-js": () => import("./../../../src/slices/TopNavigation.js" /* webpackChunkName: "slice---src-slices-top-navigation-js" */)
}

