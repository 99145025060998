import { findIndex, forEach, keys, map, omit } from 'lodash';

const CartReducer = (state, action) => {
    switch (action.type) {
        case 'LOADING':
            return {
                ...state,
                loading: true
            };
        case 'STOP_LOADING':
            return {
                ...state,
                loading: false
            };
        case 'MOUNT_CART':
            return {
                ...state,
                items: action.items,
                discounts: action.discounts,
                loading: false,
                hasMounted: true,
                totals: action.totals,
                shippingInfo: action.shippingInfo,
                customerInfo: action.customerInfo
            };
        case 'UPDATE_ITEMS':
            return {
                ...state,
                loading: false,
                items: action.items,
                totals: action.totals,
                discounts: action.discounts
            };
        case 'TOGGLE_ITEMS_UPDATED':
            return {
                ...state,
                itemsUpdated: !state.itemsUpdated,
                newItem: state.items.length
                    ? state.items[state.items.length - 1]
                    : {}
            };
        case 'CLEAR':
            return {
                ...state,
                items: [],
                totals: {
                    tax: 0,
                    shipping: 0,
                    subTotal: 0,
                    giftCardBalance: 0,
                    grandTotal: 0
                },
                shippingInfo: {},
                customerInfo: action.clearCustomer ? {} : state.customerInfo,
                discounts: []
            };
        case 'CLEAR_INVOICES':
            return {
                ...state,
                items: map(state.items, (item) => omit(item, ['invoice_id']))
            };
        case 'UPDATE_GIFT_CARD_INFO':
            const newItems = [...state.items];
            forEach(keys(action.giftCards), (cartItemId) => {
                const index = findIndex(newItems, { cartItemId });
                newItems[index].giftCardInfo = action.giftCards[cartItemId];
            });
            return {
                ...state,
                items: newItems
            };
        case 'UPDATE_VEHICLES':
            return {
                ...state,
                loading: false,
                items: action.items,
                totals: {
                    ...state.totals,
                    tax: action.tax,
                    discounts: action.discounts,
                    grandTotal: action.grandTotal,
                    subTotal: action.subTotal,
                    shipping: action.shipping
                }
            };
        case 'UPDATE_PHYSICAL_PRODUCT_INFO':
            return {
                ...state,
                shippingInfo: action.shippingInfo
            };
        case 'UPDATE_CUSTOMER_INFO':
            return {
                ...state,
                customerInfo: action.customerInfo
            };
        case 'UPDATE_CART_WITH_VEHICLE_INFO':
            return {
                ...state,
                items: action.newItems
            };
        default:
            return state;
    }
};

export default CartReducer;
