import { ThemeProvider } from '@emotion/react';
import React from 'react';

import { GravityFormsProvider } from '~/context/GravityFormsContext';
import { StickyProvider } from '~/context/StickyContext';
import { LocationProvider } from '~/context/LocationContext';
import { LocationPickerProvider } from '~/context/LocationPickerContext';
import { UserContextProvider } from '~/context/UserContext';
import { CartContextProvider } from '~/context/CartContext';

import THEME from '~/theme';

const GlobalContext = ({ children }) => (
    <LocationPickerProvider>
        <UserContextProvider>
            <CartContextProvider>
                <GravityFormsProvider>
                    <StickyProvider>
                        <ThemeProvider theme={THEME}>{children}</ThemeProvider>
                    </StickyProvider>
                </GravityFormsProvider>
            </CartContextProvider>
        </UserContextProvider>
    </LocationPickerProvider>
);

export const wrapRootElement = ({ element, props }) => {
    return <GlobalContext props={props}>{element}</GlobalContext>;
};

export const wrapPageElement = ({ element, props }) => {
    return <LocationProvider pageProps={props}>{element}</LocationProvider>;
};
