import { useEffect } from 'react';
import { useDebouncedCallback } from 'use-debounce';

const useOnWindowResize = (resizeListener, fireOnPageLoad = true) => {
    const debounced = useDebouncedCallback(resizeListener, 200);

    useEffect(() => {
        if (fireOnPageLoad) {
            resizeListener();
        }

        window.addEventListener('resize', debounced);

        return () => {
            window.removeEventListener('resize', debounced);
        };
    }, [resizeListener, fireOnPageLoad, debounced]);
};

export default useOnWindowResize;
