import { cloneDeep, merge, includes, isEmpty } from 'lodash';
import { getErrorMessage, getToken } from '~/account/utilities/helpers';

const removeSlashesRegex = '/^\\/+|\\/+$/';

const buildApiUrl = (route, isLegacy) => {
    const baseUrl = process.env.GATSBY_CMS_BASE_URL.replace(
        removeSlashesRegex,
        ''
    );

    let apiBase = process.env.GATSBY_CMS_AMP_API_BASE.replace(
        removeSlashesRegex,
        ''
    );

    if (isLegacy) {
        apiBase = process.env.GATSBY_CMS_AMP_API_BASE_LEGACY_INVOICE.replace(
            removeSlashesRegex,
            ''
        );
    }

    return `${baseUrl}/${apiBase}/${route}`;
};

/**
 * @param {String} route
 * @param {Object} options {@link https://developer.mozilla.org/en-US/docs/Web/API/fetch#options}
 * @param {Boolean} returnJson
 * @param {Boolean} isLegacy
 * @returns {Promise<any>}
 */
export const ampFetch = async ({
    route,
    options,
    returnJson = false,
    isLegacy = false
}) => {
    const url = buildApiUrl(route, isLegacy);

    const fetchOptions = {
        headers: {
            'Content-Type': 'application/json',
            'Authorization': route.startsWith('api-user')
                ? `Bearer ${getToken()}`
                : null
        }
    };

    merge(fetchOptions, options);

    try {
        const response = await fetch(url, fetchOptions);

        try {
            const json = await response.json();
            return returnJson ? json : new Response(json, response.ok);
        } catch (error) {
            // json() error indicates no response body,
            // check for response status.
            if (!response.ok) {
                return new Response(
                    {
                        error: {
                            id: response.status,
                            message: response.statusText
                        }
                    },
                    false
                );
            } else {
                return new Response(
                    {
                        success: true
                    },
                    response.ok
                );
            }
        }
    } catch (error) {
        console.error(error);

        return new Response({
            error: {
                id: 'UNKNOWN',
                message: error.message
            }
        });
    }
};

/**
 * Normalize the response object.
 * AMP API has many response objects with inconsistent HTTP codes.
 */

const successStatuses = ['SUCCESS', 'DRAFT', 'canceled', 'active', 'OPEN'];

export class Response {
    data = null;
    errorCode = null;
    errorMessage = null;
    success = null;
    refunds = null;

    constructor(response, ok) {
        this.data = cloneDeep(response);

        const { error, status, message, refunds } = response;
        if (!isEmpty(refunds)) {
            this.refunds = refunds;
        }

        if (error && error?.id) {
            this.errorCode = error.id;
        } else {
            this.errorCode = !includes(successStatuses, status) ? status : null;
        }

        if (this.errorCode) {
            if (error?.message) {
                this.errorMessage = error.message;
            } else {
                this.errorMessage = message
                    ? message
                    : getErrorMessage(this.errorCode);
            }
        }

        this.success = ok && !this.errorCode;

        if (this.success) {
            delete this.data.code;
            delete this.data.status;
            delete this.data.message;
        }
    }
}
