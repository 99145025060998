import { ampFetch } from './api';
import { has, isEmpty, reduce } from 'lodash';

const physicalProducts = {
    createPhysicalProductsDraftInvoice: async (
        customer_id,
        items,
        promo_code = null,
        invoice_id = null
    ) => {
        const itemsKey = isEmpty(items)
            ? 'NO_ITEMS'
            : reduce(
                  items,
                  (acc, item) => {
                      return acc + item.quantity;
                  },
                  ''
              );
        const promoKey = promo_code ?? 'NO_PROMO_KEY';
        const invoiceIdKey = invoice_id ?? 'NO_INVOICE_ID';

        const refreshKey = `${customer_id}-${itemsKey}-${promoKey}-${invoiceIdKey}`;

        const item = items[0];

        if (item?.refreshKey === refreshKey && has(item, 'invoice')) {
            return { data: item.invoice, success: true, refreshKey };
        }

        const response = await ampFetch({
            route: 'api/create_physical_products_draft_invoice',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    customer_id,
                    items,
                    invoice_id,
                    promo_code
                })
            }
        });

        return { ...response, refreshKey };
    }
};

export default physicalProducts;
