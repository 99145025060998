import { map, kebabCase } from 'lodash';
import { isBrowser } from '~/utilities/helpers';

export const getUserLocationFromIP = async () => {
    try {
        // Try to get the nearest location based on IP address
        const response = await fetch(
            `${process.env.GATSBY_CMS_BASE_URL}/wp-json/ipapi/v1/geolocation`
        );
        const data = await response.json();
        return {
            latitude: data.latitude,
            longitude: data.longitude
        };
    } catch (error) {
        console.error('Error getting user location using IP address', error);
        return null;
    }
};

const deg2rad = (deg) => {
    return deg * (Math.PI / 180);
};

const getDistance = (lat1, lon1, lat2, lon2) => {
    const R = 3958.8; // Radius of the earth in miles
    const dLat = deg2rad(lat2 - lat1);
    const dLon = deg2rad(lon2 - lon1);
    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(deg2rad(lat1)) *
            Math.cos(deg2rad(lat2)) *
            Math.sin(dLon / 2) *
            Math.sin(dLon / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c; // Distance in km
};

export const convertYextGeocodeToGoogleMaps = (geocodedCoordinate) => {
    return {
        lat: geocodedCoordinate.latitude,
        lng: geocodedCoordinate.longitude
    };
};

export const sortLocationsByDistance = (userPosition, locations) => {
    if (!userPosition) {
        return locations;
    }
    const { latitude: userLat, longitude: userLon } = userPosition;
    const sortedLocations = locations.sort((a, b) => {
        const aDistance = getDistance(
            userLat,
            userLon,
            a.yextRoutableCoordinate.latitude,
            a.yextRoutableCoordinate.longitude
        );
        const bDistance = getDistance(
            userLat,
            userLon,
            b.yextRoutableCoordinate.latitude,
            b.yextRoutableCoordinate.longitude
        );
        return aDistance - bDistance;
    });

    // Add distances to each location from the user's location
    return map(sortedLocations, (location) => {
        return {
            ...location,
            distance: getDistance(
                userLat,
                userLon,
                location.yextRoutableCoordinate.latitude,
                location.yextRoutableCoordinate.longitude
            )
        };
    });
};

export const urlEndcodeAddress = (address) => {
    const { line1, city, region, postalCode } = address;
    const addressString = `${line1}, ${city}, ${region} ${postalCode}`;
    return encodeURIComponent(addressString);
};

export const getCoordinates = async (address) => {
    if (isBrowser()) {
        const geocodeUrl = `${process.env.GATSBY_CMS_BASE_URL}/wp-json/autobell/v1/geocode-zipcode?zipcode=${address}`;
        try {
            const response = await fetch(geocodeUrl);
            const { location } = await response.json();
            return location;
        } catch (error) {
            console.error('Error getting zip code coordinates:', error);
            return null;
        }
    }
    return null;
};

export const getLocationUrl = (location) => {
    return `/location/${kebabCase(location.folder.name)}/${kebabCase(location.address.city)}/${kebabCase(location.address.line1)}`;
};

export const states = [
    { value: 'AL', label: 'Alabama' },
    { value: 'AK', label: 'Alaska' },
    { value: 'AZ', label: 'Arizona' },
    { value: 'AR', label: 'Arkansas' },
    { value: 'CA', label: 'California' },
    { value: 'CO', label: 'Colorado' },
    { value: 'CT', label: 'Connecticut' },
    { value: 'DE', label: 'Delaware' },
    { value: 'DC', label: 'District Of Columbia' },
    { value: 'FL', label: 'Florida' },
    { value: 'GA', label: 'Georgia' },
    { value: 'HI', label: 'Hawaii' },
    { value: 'ID', label: 'Idaho' },
    { value: 'IL', label: 'Illinois' },
    { value: 'IN', label: 'Indiana' },
    { value: 'IA', label: 'Iowa' },
    { value: 'KS', label: 'Kansas' },
    { value: 'KY', label: 'Kentucky' },
    { value: 'LA', label: 'Louisiana' },
    { value: 'ME', label: 'Maine' },
    { value: 'MD', label: 'Maryland' },
    { value: 'MA', label: 'Massachusetts' },
    { value: 'MI', label: 'Michigan' },
    { value: 'MN', label: 'Minnesota' },
    { value: 'MS', label: 'Mississippi' },
    { value: 'MO', label: 'Missouri' },
    { value: 'MT', label: 'Montana' },
    { value: 'NE', label: 'Nebraska' },
    { value: 'NV', label: 'Nevada' },
    { value: 'NH', label: 'New Hampshire' },
    { value: 'NJ', label: 'New Jersey' },
    { value: 'NM', label: 'New Mexico' },
    { value: 'NY', label: 'New York' },
    { value: 'NC', label: 'North Carolina' },
    { value: 'ND', label: 'North Dakota' },
    { value: 'OH', label: 'Ohio' },
    { value: 'OK', label: 'Oklahoma' },
    { value: 'OR', label: 'Oregon' },
    { value: 'PA', label: 'Pennsylvania' },
    { value: 'RI', label: 'Rhode Island' },
    { value: 'SC', label: 'South Carolina' },
    { value: 'SD', label: 'South Dakota' },
    { value: 'TN', label: 'Tennessee' },
    { value: 'TX', label: 'Texas' },
    { value: 'UT', label: 'Utah' },
    { value: 'VT', label: 'Vermont' },
    { value: 'VA', label: 'Virginia' },
    { value: 'WA', label: 'Washington' },
    { value: 'WV', label: 'West Virginia' },
    { value: 'WI', label: 'Wisconsin' },
    { value: 'WY', label: 'Wyoming' }
];
