import { upperFirst, camelCase, split, replace } from 'lodash';
import * as sanitizeHTML from 'sanitize-html';
import parse from 'html-react-parser';
import { startsWith } from 'lodash';

import { layout } from '~/theme';

export const isBrowser = () => typeof window !== 'undefined';

export const blockNameTransform = (name) => {
    let type, block;
    [type, block] = split(name, '/');

    type = upperFirst(camelCase(type));
    block = upperFirst(camelCase(block));

    return `${type}/${block}Block`;
};

export const sanitizeAndParseHTML = (html, parseOptions = {}) => {
    return parse(
        sanitizeHTML(html, {
            allowedTags: false,
            allowedAttributes: false,
            allowVulnerableTags: true
        }),
        parseOptions
    );
};

export const formatText = (text, parseOptions = {}) => {
    const regex = /[\u00A9\u2122\u00AE\u2120]/g;
    return sanitizeAndParseHTML(
        replace(text, regex, (match) => `<sup>${match}</sup>`),
        parseOptions
    );
};

export const stripTags = (html) => {
    return sanitizeHTML(html, {
        allowedTags: [],
        allowedAttributes: []
    });
};

export const hexToRgba = (hex, opacity) => {
    const hexValue = hex.replace('#', '');
    const r = parseInt(hexValue.substring(0, 2), 16);
    const g = parseInt(hexValue.substring(2, 4), 16);
    const b = parseInt(hexValue.substring(4, 6), 16);
    return `rgba( ${r}, ${g}, ${b}, ${opacity} )`;
};

export function scrollToElement(element, offset = 0) {
    if (!element || !isBrowser) return;

    const y = element.getBoundingClientRect().top + window.scrollY - offset;

    window.scrollTo({
        top: y,
        scrollBehavior: 'smooth'
    });
}

export function getElementFromHash(hash) {
    const trimmedHash = hash.replaceAll('/', '');
    let element = null;

    if (isBrowser) {
        element = document.querySelector(trimmedHash);
    }

    return element;
}

export const parseQueryString = (queryString) => {
    const params = new URLSearchParams(queryString);
    const query = {};
    for (const [key, value] of params) {
        query[key] = value;
    }
    return query;
};

export const stringifyQueryString = (queryParamsObj) => {
    let queryString = '';
    for (const key in queryParamsObj) {
        queryString += `${key}=${queryParamsObj[key]}&`;
    }
    return queryString.slice(0, -1);
};
export const buildThresholdList = (numSteps = 100) => {
    let thresholds = [];
    for (let i = 1.0; i <= numSteps; i++) {
        let ratio = i / numSteps;
        thresholds.push(ratio);
    }
    thresholds.push(0);
    return thresholds;
};

export const dynamicFontSize = (min, fontSize, max) => {
    return `clamp(${min}rem, ${(fontSize / layout.fontComp) * 1000}vw, ${max}rem)`;
};

export const formatTime = (time) => {
    const parts = time.split(':');
    const hour = parseInt(parts[0]);
    const minutes = parts[1];
    const ampm = hour >= 12 ? 'pm' : 'am';
    const formattedHour = hour % 12 || 12;
    const formattedMinutes = minutes === '00' ? '' : `:${minutes}`;
    return `${formattedHour}${formattedMinutes}${ampm}`;
};

export const formatPhoneNumber = (phoneNumber) => {
    let cleaned = phoneNumber;
    if (startsWith(phoneNumber, '+1')) {
        cleaned = phoneNumber.slice(2);
    }
    cleaned = cleaned.replace(/\D/g, '');
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    return match ? `${match[1]}-${match[2]}-${match[3]}` : null;
};

export const stateLabelValues = [
    { label: 'Alabama', value: 'AL' },
    { label: 'Alaska', value: 'AK' },
    { label: 'Arizona', value: 'AZ' },
    { label: 'Arkansas', value: 'AR' },
    { label: 'California', value: 'CA' },
    { label: 'Colorado', value: 'CO' },
    { label: 'Connecticut', value: 'CT' },
    { label: 'Delaware', value: 'DE' },
    { label: 'District of Columbia', value: 'DC' },
    { label: 'Florida', value: 'FL' },
    { label: 'Georgia', value: 'GA}' },
    { label: 'Hawaii', value: 'HI' },
    { label: 'Idaho', value: 'ID' },
    { label: 'Illinois', value: 'IL' },
    { label: 'Indiana', value: 'IN' },
    { label: 'Iowa', value: 'IA' },
    { label: 'Kansas', value: 'KS' },
    { label: 'Kentucky', value: 'KY' },
    { label: 'Louisiana', value: 'LA' },
    { label: 'Maine', value: 'ME' },
    { label: 'Maryland', value: 'MD' },
    { label: 'Massachusetts', value: 'MA' },
    { label: 'Michigan', value: 'MI' },
    { label: 'Minnesota', value: 'MN' },
    { label: 'Mississippi', value: 'MS' },
    { label: 'Missouri', value: 'MO' },
    { label: 'Montana', value: 'MT' },
    { label: 'Nebraska', value: 'NE' },
    { label: 'Nevada', value: 'NV' },
    { label: 'New Hampshire', value: 'NH' },
    { label: 'New Jersey', value: 'NJ' },
    { label: 'New Mexico', value: 'NM' },
    { label: 'New York', value: 'NY' },
    { label: 'North Carolina', value: 'NC' },
    { label: 'North Dakota', value: 'ND' },
    { label: 'Ohio', value: 'OH' },
    { label: 'Oklahoma', value: 'OK' },
    { label: 'Oregon', value: 'OR' },
    { label: 'Pennsylvania', value: 'PA' },
    { label: 'Puerto Rico', value: 'PR' },
    { label: 'Rhode Island', value: 'RI' },
    { label: 'South Carolina', value: 'SC' },
    { label: 'South Dakota', value: 'SD' },
    { label: 'Tennessee', value: 'TN' },
    { label: 'Texas', value: 'TX' },
    { label: 'Utah', value: 'UT' },
    { label: 'Vermont', value: 'VT' },
    { label: 'Virgin Islands', value: 'VI' },
    { label: 'Virginia', value: 'VA' },
    { label: 'Washington', value: 'WA' },
    { label: 'West Virginia', value: 'WV' },
    { label: 'Wisconsin', value: 'WI' },
    { label: 'Wyoming', value: 'WY' }
];

export const graphqlFetch = async (query) => {
    if (!isBrowser()) return null;
    const response = await fetch(process.env.GATSBY_CMS_GRAPHQL_URL, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({
            query
        })
    });

    const { data, errors } = await response.json();

    if (errors) {
        throw new Error(errors);
    }

    return data;
};
