import React, { useContext, createContext } from 'react';

const LocationContext = createContext();

export const LocationProvider = ({ children, pageProps }) => {
    return (
        <LocationContext.Provider value={pageProps?.location || {}}>
            {children}
        </LocationContext.Provider>
    );
};

export const useLocation = () => {
    const context = useContext(LocationContext);

    if (context === undefined) {
        throw new Error(
            'useLocationContext must be used within a LocationProvider'
        );
    }

    return context;
};
