import { useState, useEffect } from 'react';
import { getUserLocationFromIP } from '~/utilities/locations';
import Cookies from 'universal-cookie';

const getLocationFromCookie = (cookies) => {
    return cookies.get(LOCATION_COOKIE_NAME);
};

const setLocationCookie = (location, cookies) => {
    cookies.set(LOCATION_COOKIE_NAME, location, {
        path: '/',
        sameSite: 'strict'
    });
};

export const LOCATION_COOKIE_NAME = 'userLocation';

const useUserLocation = () => {
    const [userLocation, setUserLocation] = useState(null);

    useEffect(() => {
        const cookies = new Cookies();
        let watchID;
        if (userLocation) {
            setLocationCookie(userLocation, cookies);
        } else {
            const cookieLocation = getLocationFromCookie(cookies);
            if (cookieLocation) {
                setUserLocation(cookieLocation);
            }
            watchID = navigator.geolocation.watchPosition(
                (location) => {
                    setUserLocation({
                        latitude: location.coords.latitude,
                        longitude: location.coords.longitude
                    });
                },
                async (error) => {
                    if (
                        !userLocation &&
                        error.code === error.PERMISSION_DENIED
                    ) {
                        console.error('Error getting user location', error);
                        const location = await getUserLocationFromIP();
                        setUserLocation(location);
                    }
                }
            );
        }

        return () => {
            navigator.geolocation.clearWatch(watchID);
            cookies.remove(LOCATION_COOKIE_NAME);
        };
    }, [userLocation]);

    return { userLocation, setUserLocation };
};

export default useUserLocation;
