export const fonts = {
    heading: 'Benton Sans, sans-serif',
    body: 'Benton Sans Book, sans-serif'
};

const fontWeights = {
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900
};

export const colors = {
    primary: {
        yellow: '#EEB111',
        red: '#EE3524',
        blue: '#2158A8',
        darkBlue: '#006AAA',
        black: '#000000',
        white: '#FFFFFF',
        gray: '#444B53',
        lightGray: '#EDEDED'
    },
    accent: {
        blue: '#1A488D',
        gray: '#414042',
        lightGray: '#A6A6A6',
        yellow: '#FFD200',
        green: '#4C9A2A'
    },
    text: {
        red: '#ED3524',
        black: '#1E2226',
        blackRgb: '30, 34, 38',
        blue: '#0654B3',
        lightBlue: '#C8E5F3',
        gray: '#A6A6A6',
        white: '#FFFFFF'
    }
};

export const backgroundColors = {
    white: colors.primary.white,
    black: colors.text.black,
    blue: colors.text.blue,
    lightBlue: colors.text.lightBlue,
    darkBlue: colors.accent.blue,
    red: colors.primary.red,
    yellow: colors.primary.yellow,
    gray: '#D1D3D4',
    lightGray: '#F7FCFF',
    transparent: '#00000000'
};

export const iconColors = {
    white: colors.primary.white,
    black: colors.primary.black,
    textBlack: colors.text.black,
    blue: colors.primary.blue,
    textBlue: colors.text.blue,
    yellow: colors.primary.yellow,
    accentBlue: colors.accent.blue,
    gray: colors.accent.gray,
    backgroundGray: backgroundColors.gray,
    red: colors.primary.red,
    textRed: colors.text.red
};

export const layout = {
    maxWidth: '2000px',
    containerWidth: '1520px',
    containerWidthNumber: 1520,
    containerWidthLg: '1800px',
    containerWidthLgNumber: 1800,
    smallestScreenWidth: 375,
    gutter: '20px',
    largeGutter: '40px',
    gridGutter: '15px',
    sectionPadding: '120px',
    sectionPaddingTablet: '90px',
    sectionPaddingMobile: '70px',
    sectionPaddingMedium: '65px',
    sectionPaddingMediumTablet: '50px',
    sectionPaddingMediumMobile: '40px',
    sectionPaddingSmall: '40px',
    sectionPaddingSmallTablet: '40px',
    sectionPaddingSmallMobile: '30px',
    fontComp: 1920
};

export const breakpointNumbers = {
    phone: 0,
    largePhone: 576,
    tablet: 768,
    largeTablet: 992,
    desktop: 1200,
    headerBreak: 1560,
    largeDesktop: 2000,
    phoneMax: 575,
    largePhoneMax: 767,
    tabletMax: 991,
    largeTabletMax: 1199
};

export const breakpoint = {
    phone: '0',
    largePhone: '576px',
    tablet: '768px',
    largeTablet: '992px',
    desktop: '1200px',
    headerBreak: '1560px',
    largeDesktop: '2000px',
    phoneMax: '575px',
    largePhoneMax: '767px',
    tabletMax: '991px',
    largeTabletMax: '1199px'
};

export const zIndex = {
    backgroundColor: -4,
    backgroundImage: -3,
    textGradient: -2,
    backgroundGraphics: -1,
    mainMenu: 1,
    footerChildMenu: 2,
    userAccountDropdown: 4,
    locationPicker: 5,
    mobileMenu: 5,
    mobileTrigger: 6,
    modal: 7
};

const theme = {
    layout,
    colors,
    backgroundColors,
    iconColors,
    breakpoint,
    breakpointNumbers,
    fonts,
    fontWeights,
    zIndex
};

export default theme;
