import { ampFetch } from './api';
import { has } from 'lodash';

/**
 * @typedef GiftCardInvoiceResponse
 * @property {string} stripe_invoice_id
 * @property {string} stripe_customer_id
 * @property {string} stripe_payment_id
 * @property {string} stripe_subscription_id
 * @property {string} status
 * @property {number} total
 * @property {number} subtotal
 * @property {number} tax
 * @property {number} discount
 * @property {number} amount_paid
 * @property {number} amount_due
 * @property {number} amount_remaining
 * @property {string} period_start
 * @property {string} period_end
 * @property {string} hosted_invoice_url
 * @property {string} invoice_pdf
 * @property {boolean} is_tax_inclusive
 * @property {string} location
 * @property {string} created_date
 * @property {string} modified_date
 */

const updateItemWithInvoice = (invoice, item, refreshKey) => {
    let giftCardInfo = [];

    if (item.giftCardInfo && item.giftCardInfo.length > item.quantity) {
        const newLength = item.giftCardInfo.length - item.quantity;
        giftCardInfo = item.giftCardInfo.slice(0, newLength);
    }

    return {
        ...item,
        invoice,
        refreshKey,
        giftCardInfo,
        invoice_id: invoice.stripe_invoice_id,
        tax: invoice.tax,
        subTotal: invoice.subtotal,
        discount: invoice.discount,
        total: invoice.total,
        price: invoice.subtotal
    };
};

const giftCards = {
    /**
     * @param {object} item
     * @param {string} locationID
     * @returns object
     */
    getInvoice: async (item, locationID) => {
        const price = item.price;
        const database_id = item.id;
        const refreshKey = `${price}-${database_id}-${locationID}`;

        if (refreshKey === item.refreshKey && has(item, 'invoice')) {
            return { success: true, giftItem: item };
        }

        const response = await ampFetch({
            returnJson: true,
            route: 'api-user/gift-card-validate/get-invoice',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    location_id: locationID,
                    amount_in_cents: price,
                    database_id
                })
            }
        });

        if (response.success) {
            const giftItem = updateItemWithInvoice(
                response.invoice,
                item,
                refreshKey
            );
            return { success: true, giftItem };
        }

        return { success: false, giftItem: item };
    },
    getHistory: async () => {
        return await ampFetch({
            route: 'api-user/gift-card/get-history',
            options: {
                method: 'POST'
            }
        });
    },
    redeem: async (code) => {
        return await ampFetch({
            route: 'api-user/gift-card/redeem-gift-card-to-app-by-code',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    code
                })
            }
        });
    },
    resend: async (gift_card_id, email) => {
        return await ampFetch({
            route: 'api-user/gift-card/resend',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    gift_card_id,
                    email
                })
            }
        });
    },
    getGiftCardsBalance: async () => {
        return await ampFetch({
            route: 'api-user/gift-card/get-gift-cards-balance',
            options: {
                method: 'GET'
            }
        });
    }
};

export default giftCards;
