import React, { useEffect, useContext, createContext, useReducer } from 'react';

import { findIndex, forEach, isEmpty, keys, map, find } from 'lodash';

import CartReducer from '~/reducers/cart';

import { useLocationPickerContext } from '~/context/LocationPickerContext';
import { useUser } from '~/context/UserContext';

import { getTotals } from '~/utilities/cart';

import {
    addItemToCart,
    removeItemFromCart,
    updateCartItemAddOns,
    removeCartItemAddOn,
    updateCartPromoCode,
    removePromoCode,
    updateCartItemQuantity,
    updateCartItemLocation,
    updateCartItemPrice
} from '~/utilities/cartActions';

const CART_STORAGE_KEY = 'ab_cart';

const CartContext = createContext();

const initialCartState = {
    items: [],
    itemsUpdated: false,
    newItem: {
        title: '',
        addOns: []
    },
    loading: true,
    totals: {
        tax: 0,
        shipping: 0,
        subTotal: 0,
        grandTotal: 0,
        discount: 0,
        giftCardBalance: 0
    },
    discounts: [],
    shippingInfo: {},
    customerInfo: {},
    hasMounted: false
};

export const CartContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(CartReducer, initialCartState);
    const { user, isLoggedIn } = useUser();
    const { locations } = useLocationPickerContext();

    const mountTotals = async (
        items,
        shippingInfo,
        discounts,
        customerInfo
    ) => {
        if (items && items.length) {
            const {
                items: cartItems,
                subTotal,
                tax,
                shipping,
                giftCardBalance,
                grandTotal,
                promoCodes
            } = await getTotals(
                items,
                customerInfo ? customerInfo.stripe_customer_id : '',
                discounts
            );
            dispatch({
                type: 'MOUNT_CART',
                items: cartItems,
                totals: {
                    subTotal,
                    tax,
                    shipping,
                    giftCardBalance,
                    grandTotal
                },
                shippingInfo,
                discounts: promoCodes,
                customerInfo
            });
        } else {
            dispatch({
                type: 'MOUNT_CART',
                items: [],
                discounts: [],
                totals: {
                    subTotal: 0,
                    tax: 0,
                    shipping: 0,
                    giftCardBalance: 0,
                    grandTotal: 0
                },
                shippingInfo: shippingInfo || {},
                customerInfo: customerInfo || {}
            });
        }
    };

    useEffect(() => {
        const cart = JSON.parse(localStorage.getItem(CART_STORAGE_KEY));
        const { items, shippingInfo, discounts, customerInfo } = cart || {};
        mountTotals(items, shippingInfo, discounts, customerInfo);
    }, []);

    useEffect(() => {
        if (isLoggedIn) {
            dispatch({
                type: 'UPDATE_CUSTOMER_INFO',
                customerInfo: {
                    stripe_customer_id: user.stripe_customer_id,
                    name: user.name,
                    email: user.email,
                    phone: user.phone_number
                }
            });
        }
        return () => {
            if (state.hasMounted) {
                if (isLoggedIn) {
                    dispatch({ type: 'CLEAR', clearCustomer: true });
                } else {
                    dispatch({ type: 'CLEAR_INVOICES' });
                }
            }
        };
    }, [isLoggedIn, user, state.hasMounted]);

    useEffect(() => {
        if (state.hasMounted) {
            localStorage.setItem(
                CART_STORAGE_KEY,
                JSON.stringify({
                    items: state.items,
                    shippingInfo: state.shippingInfo,
                    discounts: state.discounts,
                    customerInfo: state.customerInfo
                })
            );
        }
    }, [
        state.items,
        state.shippingInfo,
        state.discounts,
        state.customerInfo,
        state.hasMounted
    ]);

    return (
        <CartContext.Provider
            value={{
                ...state,
                addToCart: async (
                    id,
                    displayName,
                    location,
                    addOnIds,
                    giftCardValue,
                    vehicleID,
                    garageID,
                    quantity,
                    giftCardQuantity
                ) => {
                    dispatch({ type: 'LOADING' });
                    const payload = await addItemToCart(
                        id,
                        location,
                        state.items,
                        state.customerInfo.stripe_customer_id,
                        state.discounts,
                        displayName,
                        addOnIds,
                        giftCardValue,
                        vehicleID,
                        garageID,
                        quantity,
                        giftCardQuantity
                    );
                    dispatch({ type: 'UPDATE_ITEMS', ...payload });
                    dispatch({ type: 'TOGGLE_ITEMS_UPDATED' });
                },
                toggleItemsUpdated: () => {
                    dispatch({ type: 'TOGGLE_ITEMS_UPDATED' });
                },
                addCartPromoCode: async (promoCode) => {
                    dispatch({ type: 'LOADING' });
                    const payload = await updateCartPromoCode(
                        promoCode,
                        state.discounts,
                        state.items,
                        state.customerInfo.stripe_customer_id
                    );
                    if (payload.success) {
                        dispatch({ type: 'UPDATE_ITEMS', ...payload });
                        return { success: true };
                    } else {
                        dispatch({ type: 'UPDATE_ITEMS', ...payload });
                        return {
                            success: false,
                            promoCodeErrorMsg: payload.promoCodeErrorMsg
                        };
                    }
                },
                removePromoCode: async (promoCode) => {
                    dispatch({ type: 'LOADING' });
                    const payload = await removePromoCode(
                        promoCode,
                        state.items,
                        state.customerInfo.stripe_customer_id,
                        state.discounts
                    );
                    dispatch({ type: 'UPDATE_ITEMS', ...payload });
                },
                updateCartItemAddOns: async (cartItemId, addOns, location) => {
                    dispatch({ type: 'LOADING' });
                    const payload = await updateCartItemAddOns(
                        cartItemId,
                        addOns,
                        state.items,
                        location,
                        state.discounts
                    );
                    dispatch({ type: 'UPDATE_ITEMS', ...payload });
                },
                removeFromCart: async (cartItemId) => {
                    dispatch({ type: 'LOADING' });
                    const payload = await removeItemFromCart(
                        cartItemId,
                        state.items,
                        state.customerInfo.stripe_customer_id,
                        state.discounts
                    );
                    dispatch({ type: 'UPDATE_ITEMS', ...payload });
                },
                removeAddOnFromCart: async (cartItemId, addOnId) => {
                    dispatch({ type: 'LOADING' });
                    const payload = await removeCartItemAddOn(
                        cartItemId,
                        addOnId,
                        state.items,
                        state.customerInfo.stripe_customer_id,
                        state.discounts
                    );
                    dispatch({ type: 'UPDATE_ITEMS', ...payload });
                },
                setGiftCardInformation: (giftCards) => {
                    dispatch({ type: 'UPDATE_GIFT_CARD_INFO', giftCards });
                },
                setCartVehicles: async (vehicles) => {
                    dispatch({ type: 'LOADING' });

                    let items = [...state.items];

                    forEach(keys(vehicles), (cartItemId) => {
                        const index = findIndex(items, { cartItemId });
                        items[index].vehicle = vehicles[cartItemId];
                    });

                    const stripe_customer_id = user?.stripe_customer_id;

                    const totalInfo = await getTotals(
                        items,
                        stripe_customer_id,
                        state.discounts
                    );

                    dispatch({ type: 'UPDATE_VEHICLES', ...totalInfo });
                },
                setCustomerInformation: async (customerInfo) => {
                    dispatch({ type: 'UPDATE_CUSTOMER_INFO', customerInfo });
                },
                clearCart: (clearCustomer) =>
                    dispatch({ type: 'CLEAR', clearCustomer }),
                recalculateTotals: async () => {
                    dispatch({ type: 'LOADING' });
                    const payload = await getTotals(
                        state.items,
                        state.customerInfo.stripe_customer_id
                    );
                    dispatch({ type: 'UPDATE_ITEMS', ...payload });
                },
                setPhysicalProductInfo: (shippingInfo) => {
                    dispatch({
                        type: 'UPDATE_PHYSICAL_PRODUCT_INFO',
                        shippingInfo
                    });
                },
                updateCartItemQuantity: async (
                    cartItemId,
                    quantity,
                    location
                ) => {
                    dispatch({ type: 'LOADING' });
                    const payload = await updateCartItemQuantity(
                        cartItemId,
                        quantity,
                        state.items,
                        location,
                        state.customerInfo.stripe_customer_id,
                        state.discounts
                    );
                    dispatch({ type: 'UPDATE_ITEMS', ...payload });
                },
                updateCartItemPrice: async (cartItemId, newPrice) => {
                    dispatch({ type: 'LOADING' });
                    const payload = await updateCartItemPrice(
                        cartItemId,
                        newPrice,
                        state.items,
                        state.customerInfo.stripe_customer_id,
                        state.discounts
                    );
                    dispatch({ type: 'UPDATE_ITEMS', ...payload });
                },
                updateCartItemsWithVehicleInfo: (newCartItems) => {
                    const newItems = map(state.items, (item) => {
                        const newItem = find(newCartItems, {
                            cartItemId: item.cartItemId
                        });

                        if (isEmpty(newItem)) {
                            return item;
                        }

                        return {
                            ...item,
                            ...newItem
                        };
                    });

                    dispatch({
                        type: 'UPDATE_CART_WITH_VEHICLE_INFO',
                        newItems
                    });
                },
                updateCartItemLocation: async (cartItemId, locationID) => {
                    dispatch({ type: 'LOADING' });
                    const location = find(locations, { ampID: locationID });
                    const payload = await updateCartItemLocation(
                        cartItemId,
                        location,
                        state.items,
                        state.customerInfo.stripe_customer_id,
                        state.discounts
                    );
                    dispatch({ type: 'UPDATE_ITEMS', ...payload });
                }
            }}
        >
            {children}
        </CartContext.Provider>
    );
};

export const useCart = () => useContext(CartContext);
