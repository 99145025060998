import { ampFetch } from './api';
import { find, has, isEmpty, map, parseInt, reduce, sumBy } from 'lodash';
import { isLoggedIn } from '~/account/utilities/helpers';

const updateItemWithInvoice = (data, item, refreshKey) => {
    if (!has(data, 'invoice')) {
        console.error(`No invoice found for ${data}`);
        return item;
    }

    const invoice = data.invoice;

    return {
        ...item,
        refreshKey,
        tax: invoice.tax || 0,
        invoice: {
            ...invoice,
            checkout_id: data.checkout_id
        },
        metadata: {
            amount_due: data.amount_due,
            mobile_app_discount: data.mobile_app_discount
        }
    };
};

const updateItemWithDiscount = (discountInvoice, code, item) => {
    const discountTotal =
        discountInvoice.total === 0
            ? item.price
            : item.invoice.subtotal - discountInvoice.subtotal;
    const discount = discountInvoice.discount ?? discountTotal;

    return {
        ...item,
        tax: discountInvoice.tax,
        discount,
        invoice: {
            ...discountInvoice,
            subtotal: item.invoice.subtotal,
            discount,
            checkout_id: item.invoice.checkout_id
        },
        promoCode: {
            code,
            id: discountInvoice.stripe_invoice_id
        }
    };
};

const deleteInvoice = async (invoice_id, accountId) => {
    return await ampFetch({
        route: 'api-user/invoices/delete-draft',
        options: {
            method: 'POST',
            body: JSON.stringify({ invoice_id, 'Amp-Account-Id': accountId })
        }
    });
};

const washes = {
    deleteInvoice: async (invoiceId, garage) => {
        deleteInvoice(invoiceId, garage).catch((e) => {
            console.error(e.message);
        });
    },
    deleteInvoiceById: (id, cartItems) => {
        const item = find(cartItems, {
            cartItemId: id,
            productType: 'digital'
        });
        const invoiceId = item?.invoice?.stripe_invoice_id;
        const garage = item?.vehicle?.garage;

        if (item && invoiceId && garage) {
            deleteInvoice(invoiceId, garage).catch((e) => {
                console.error(e.message);
            });
        }
    },
    getRemoteWashes: async (paging) => {
        return await ampFetch({
            route: 'api-user/washes/get-remote-washes',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    paging,
                    filters: {
                        statuses: [
                            'REDEEMED',
                            'UNREDEEMED',
                            'REVOKED',
                            'VOID',
                            'EXPIRED'
                        ]
                    }
                })
            }
        });
    },
    addPromoCode: async (code, locationId, item) => {
        const response = await ampFetch({
            route: 'api-user/invoices/add-promo-code-to-wash',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    'Amp-Account-Id': item?.vehicle?.garage,
                    'promo_code': code,
                    'invoice_id': item?.invoice?.stripe_invoice_id,
                    'wash_type_id': item?.ampId,
                    'add_on_ids': map(item?.addOns, (addOn) =>
                        parseInt(addOn.ampId)
                    ),
                    'location_id': locationId,
                    'vehicle_id': parseInt(item?.vehicle?.vehicleId)
                })
            }
        });

        if (response.success) {
            const newItem = updateItemWithDiscount(response.data, code, item);
            return { value: newItem, success: true };
        }

        return { value: item, success: false };
    },
    addInvoice: async (locationId, item, promotionCode = null) => {
        const digitalTotal =
            (item.price + sumBy(item.addOns, 'price')) * item.quantity;

        if (!has(item, 'vehicle') || !isLoggedIn()) {
            return { success: false, digitalItem: item, digitalTotal };
        }

        const accountId = item?.vehicle?.garage;
        const washTypeId = item?.ampId;
        const vehicleId = item?.vehicle?.vehicleId;
        const code = promotionCode || item?.promoCode?.code;
        const addonsId = isEmpty(item?.addOns)
            ? 'NO_ADDONS'
            : reduce(
                  item?.addOns,
                  (acc, addon) => {
                      return acc + addon.ampId;
                  },
                  ''
              );

        const refreshKey = `${accountId}-${washTypeId}-${vehicleId}-${locationId}-${addonsId}-${code || 'NO_CODE'}`;

        if (item.refreshKey === refreshKey && has(item, 'invoice')) {
            return {
                success: true,
                digitalItem: item,
                digitalTotal: item.invoice.total
            };
        }

        if (item?.invoice?.stripe_invoice_id) {
            const id = item.invoice.stripe_invoice_id;
            // delete old draft invoice
            deleteInvoice(id, accountId).catch((e) => console.error(e.message));
        }

        const response = await ampFetch({
            route: 'api-user/washes/checkout-remote-wash',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    'Amp-Account-Id': accountId,
                    'wash_type_id': washTypeId,
                    'timezone_offset': 0,
                    'add_on_ids': map(item?.addOns, (addOn) =>
                        parseInt(addOn.ampId)
                    ),
                    'location_id': locationId,
                    'vehicle_id': parseInt(vehicleId)
                })
            }
        });

        if (response.success) {
            const digitalItem = updateItemWithInvoice(
                response.data,
                item,
                refreshKey
            );

            const hasInvoice = has(digitalItem, 'invoice');
            const finalTotal = hasInvoice
                ? digitalItem.invoice.total
                : digitalTotal;

            return {
                digitalItem,
                success: hasInvoice,
                digitalTotal: finalTotal
            };
        }

        return { success: false, digitalItem: item, digitalTotal };
    }
};

export default washes;
