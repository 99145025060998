import { isBrowser } from '~/utilities/helpers';
/**
 *
 * @param eventType
 * @param data
 */
const trackCustomEvent = (eventType, data = {}) => {
    if (typeof window === 'undefined') {
        return;
    }
    window.dataLayer = window.dataLayer || [];

    let trackingPayload = {};
    if (!eventType) {
        if (isBrowser) {
            console.error('eventType is required');
        }
        return;
    }
    trackingPayload.event = eventType;
    if (data.action) {
        trackingPayload.eventAction = data.action;
    }
    if (data.category) {
        trackingPayload.eventCategory = data.category;
    }
    if (data.label) {
        trackingPayload.eventLabel = data.label;
    }
    if (data.value) {
        trackingPayload.eventValue = data.value;
    }
    if (data.additionalTracking) {
        trackingPayload = { ...trackingPayload, ...data.additionalTracking };
    }
    window.dataLayer.push(trackingPayload);
};

export default trackCustomEvent;
