import React, { useContext, createContext, useEffect, useReducer } from 'react';
import { cloneDeep } from 'lodash';

import userApi from '~/account/api/user';
import UserReducer from '~/reducers/user';

import {
    setDefaultVehicleId as setDefaultVehicleIdLocal,
    isLoggedIn as localIsLoggedIn,
    getToken,
    getTokenExpiration,
    saveToken,
    resetToken
} from '~/account/utilities/helpers';

const UserContext = createContext();

const initialUserState = {
    loggedIn: false,
    loading: true,
    user: {},
    token: null,
    defaultVehicleId: null,
    error: null
};

const getUser = async () => {
    const { data, errorMessage } = await userApi.getCurrentUser();

    if (data) {
        return data;
    } else if (errorMessage) {
        return {
            error: errorMessage
        };
    }
    return {
        error: 'An unknown error occurred.'
    };
};

export const UserContextProvider = ({ children }) => {
    const [state, dispatch] = useReducer(UserReducer, initialUserState);

    useEffect(() => {
        let didCancel = false;
        async function initialLogin() {
            if (didCancel) {
                return;
            }
            const userData = await getUser();
            if (userData.error) {
                dispatch({ type: 'ERROR', error: userData.error });
                return;
            }
            dispatch({
                type: 'LOGIN',
                user: userData,
                token: {
                    token: getToken(),
                    expires_at: getTokenExpiration()
                }
            });
        }
        if (localIsLoggedIn()) {
            dispatch({ type: 'LOADING' });
            initialLogin();
        } else {
            dispatch({ type: 'LOADED' });
        }
        return () => {
            didCancel = true;
        };
    }, []);

    useEffect(() => {
        setDefaultVehicleIdLocal(state.defaultVehicleId);
    }, [state.defaultVehicleId]);

    return (
        <UserContext.Provider
            value={{
                isLoggedIn: state.loggedIn,
                loading: state.loading,
                user: state.user,
                userError: state.error,
                refreshUser: async () => {
                    const user = await getUser();
                    if (user.error) {
                        dispatch({ type: 'ERROR', error: user.error });
                    } else {
                        dispatch({ type: 'UPDATE_USER', user });
                    }
                },
                defaultVehicleId: state.defaultVehicleId,
                setDefaultVehicleId: (vehicleId) => {
                    dispatch({ type: 'UPDATE_DEFAULT_VEHICLE', vehicleId });
                },
                login: async (username, password) => {
                    let response = {};
                    const [signInResponse, authDataResponse] =
                        await Promise.all([
                            userApi.signIn(username, password),
                            userApi.getAuthData(username)
                        ]);

                    const { success, data } = signInResponse;
                    const {
                        data: {
                            auth_status,
                            has_auth_id,
                            has_amp_user,
                            has_password
                        }
                    } = authDataResponse;

                    if (success) {
                        if (auth_status === 'CONFIRMED') {
                            if (has_amp_user) {
                                saveToken(data.success);
                                const user = await getUser();
                                if (user.error) {
                                    dispatch({
                                        type: 'ERROR',
                                        error: data.error
                                    });
                                } else {
                                    dispatch({
                                        type: 'LOGIN',
                                        user,
                                        token: data.success
                                    });
                                }
                            } else {
                                response = {
                                    errorCode: 'NO_AMP_USER',
                                    errorMessage: null
                                };
                            }
                        }
                    } else {
                        // Special case for migrated users.
                        if (
                            !auth_status &&
                            has_amp_user &&
                            !has_auth_id &&
                            !has_password
                        ) {
                            response = {
                                errorCode: 'SHELL_ACCOUNT',
                                errorMessage: null
                            };
                        } else if (data?.status === 'NEW_PASSWORD_REQUIRED') {
                            response = cloneDeep(signInResponse);
                            response.errorMessage = null;
                        }
                    }

                    return response?.errorCode ? response : signInResponse;
                },
                logout: async () => {
                    await userApi.signOut(state.token);
                    resetToken();
                    dispatch({ type: 'LOGOUT' });
                    return true;
                }
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

export const useUser = () => useContext(UserContext);
