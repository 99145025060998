import React, { createContext, useContext, useReducer, useEffect } from 'react';
import Cookies from 'universal-cookie';
import { find, head } from 'lodash';
import { useJsApiLoader } from '@react-google-maps/api';

import useLocations from '~/hooks/useLocations';
import { sortLocationsByDistance, getCoordinates } from '~/utilities/locations';
import trackCustomEvent from '~/utilities/trackCustomEvent';

import { LOCATION_COOKIE_NAME } from '~/hooks/useUserLocation';

const LocationPickerContext = createContext();

const SELECTED_COOKIE_NAME = 'selectedLocation';

const setSelectedLocationCookie = (selectedLocation) => {
    const cookies = new Cookies();
    cookies.set(SELECTED_COOKIE_NAME, selectedLocation, {
        path: '/',
        sameSite: 'strict'
    });
};

const locationPickerReducer = (state, action) => {
    const { type } = action;

    switch (type) {
        case 'SET_LOADING':
            return { ...state, loading: true };
        case 'MOUNT':
            return {
                ...state,
                hasMounted: true,
                selectedLocation: find(state.locations, {
                    id: action.selectedLocation
                }),
                currentDay: action.currentDay,
                userLocation: action.userLocation
            };
        case 'SET_USER_LOCATION':
            const sortedLocations = sortLocationsByDistance(
                action.userLocation,
                state.locations
            );
            let newState = {
                ...state,
                userLocation: action.userLocation,
                locations: sortedLocations,
                address: action.address ?? state.address,
                loading: false
            };
            if (action.userLocation) {
                newState.selectedLocation =
                    state.selectedLocation ?? head(sortedLocations);
            }
            return newState;
        case 'SET_SELECTED_LOCATION':
            return {
                ...state,
                selectedLocation: find(state.locations, {
                    id: action.selectedLocation
                })
            };
        default:
            return state;
    }
};

const dayOfWeek = () => {
    const date = new Date();
    const day = date.getDay();
    return [
        'sunday',
        'monday',
        'tuesday',
        'wednesday',
        'thursday',
        'friday',
        'saturday'
    ][day];
};

export const LocationPickerProvider = ({ children }) => {
    const locations = useLocations();

    const { isLoaded: isGoogleMapsLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.GATSBY_GOOGLE_MAPS_API_KEY,
        id: 'google-map-script'
    });

    const [state, dispatch] = useReducer(locationPickerReducer, {
        loading: false,
        hasMounted: false,
        locations,
        userLocation: null,
        address: '',
        selectedLocation: null,
        currentDay: 'sunday'
    });

    useEffect(() => {
        const cookies = new Cookies();
        dispatch({
            type: 'MOUNT',
            selectedLocation: cookies.get(SELECTED_COOKIE_NAME),
            currentDay: dayOfWeek(),
            userLocation: cookies.get(LOCATION_COOKIE_NAME)
        });
    }, []);

    useEffect(() => {
        if (state.selectedLocation) {
            setSelectedLocationCookie(state.selectedLocation.id);
        }
    }, [state.selectedLocation]);

    return (
        <LocationPickerContext.Provider
            value={{
                locations: state.locations,
                selectedLocation: state.selectedLocation,
                currentDay: state.currentDay,
                isGoogleMapsLoaded,
                setUserLocation: (userLocation) =>
                    dispatch({ type: 'SET_USER_LOCATION', userLocation }),
                setLocation: (locationID) => {
                    dispatch({
                        type: 'SET_SELECTED_LOCATION',
                        selectedLocation: locationID
                    });
                    trackCustomEvent('set_location', { value: locationID });
                },
                loading: state.loading,
                address: state.address,
                setManualLocation: async (address) => {
                    dispatch({ type: 'SET_LOADING' });
                    const coordinates = await getCoordinates(address);
                    if (coordinates) {
                        const cookies = new Cookies();
                        cookies.set(LOCATION_COOKIE_NAME, coordinates, {
                            path: '/',
                            sameSite: 'strict'
                        });
                        dispatch({
                            type: 'SET_USER_LOCATION',
                            userLocation: coordinates,
                            address
                        });
                        trackCustomEvent('location_search', { value: address });
                    }
                }
            }}
        >
            {children}
        </LocationPickerContext.Provider>
    );
};

export const useLocationPickerContext = () => {
    const context = useContext(LocationPickerContext);
    if (context === undefined) {
        throw new Error(
            'useLocationPicker must be used within a LocationPickerProvider'
        );
    }

    return context;
};
