let nextRoute = ``;

export const onPreRouteUpdate = ({ location }) => {
    nextRoute = location.pathname;
};

export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition
}) => {
    const currentPosition = getSavedScrollPosition(location);
    setTimeout(() => window.scrollTo(...(currentPosition || [0, 0])), 0);
    return false;
};

if (typeof window !== `undefined`) {
    window.addEventListener('unhandledrejection', function (event) {
        if (/loading chunk \d* failed./i.test(event.reason)) {
            if (nextRoute) {
                window.location.pathname = nextRoute;
            }
        }
    });

    // Fix Windows browsers not including scrollbars in vw calculations
    function setVw() {
        let vw = document.documentElement.clientWidth / 100;
        document.documentElement.style.setProperty('--vw', `${vw}px`);
    }
    setVw();
    window.addEventListener('resize', setVw);

    if (!window.crypto) {
        window.crypto = window.msCrypto;
    }
}

export { wrapRootElement } from './gatsby-ssr';

export { wrapPageElement } from './gatsby-ssr';
