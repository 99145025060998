import { ampFetch } from './api';

/**
 * @typedef {object}
 * @property {string} code
 * @property {string} message
 */
/**
 * @typedef {object} UserResponse
 * @property {string} id
 * @property {string} name
 * @property {string} email
 * @property {string} [stripe_customer_id]
 * @property {boolean} is_sms_verified
 * @property {string} [zip_code_billing]
 * @property {string} [zip_code_customer_provided]
 * @property {string} [referral_code]
 * @property {string} [birthdate] datetime
 * @property {string} [phone_number]
 * @property {boolean} is_receiving_promotion_texts
 * @property {boolean} is_receiving_account_texts
 * @property {boolean} is_receiving_account_emails
 * @property {boolean} is_receiving_promotion_emails
 */
/**
 * @typedef {'Standard'|'Fleet'|'Fleet Pay Per Wash'|'Employee'} AccountType
 */
/**
 * @typedef {object} AccountResponse
 * @property {string} name
 * @property {string} id
 * @property {AccountType} type
 * @property {SubscriptionBillingApproach} subscription_billing_approach
 * @property {string} user_id_primary_account_holder
 * @property {string} created_date datetime
 * @property {string} modified_date datetime
 * @property {string} [created_by_user_id]
 * @property {string} [modified_by_user_id]
 */
/**
 * @typedef UserAndAccountResponse
 * @property {UserResponse} user
 * @property {AccountResponse} account
 */
/**
 * @typedef {object} UserAuthData
 * @property {boolean} has_auth_id
 * @property {boolean} has_password
 * @property {boolean} has_amp_user
 * @property {'ARCHIVED'|'COMPROMISED'|'CONFIRMED'|'USE_TEMPORARY_PASSWORD'|'REQUEST_TEMPORARY_PASSWORD'|'UNCONFIRMED'|'UNKNOWN'} auth_status
 * @property {string} name
 */

const user = {
    /**
     * @param {string} email
     * @returns {Promise<UserAuthData>}
     */
    getAuthData: async (email) => {
        return await ampFetch({
            route: 'api/auth/get-auth-data',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    email
                })
            }
        });
    },
    /**
     * @typedef ChangePasswordResponse
     * @property {'SUCCESS'|'INVALID_PARAMETER'|'LIMIT_EXCEEDED'|'NOT_AUTHORIZED'|'INVALID_PASSWORD'|'PASSWORD_RESET_REQUIRED'|'FAILURE'} status
     */
    /**
     * @param {string} access_token
     * @param {string} old_password
     * @param {string} new_password
     * @returns {Promise<ChangePasswordResponse>}
     *
     * @throws error
     */
    changePassword: async (access_token, old_password, new_password) => {
        return await ampFetch({
            route: 'api/auth/sign-up',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    access_token,
                    old_password,
                    new_password
                })
            }
        });
    },
    /**
     * @param {string} session
     * @param {string} username
     * @param {string} new_password
     * @returns {Promise<>}
     *
     * @throws error
     */
    forceChangePassword: async (session, username, new_password) => {
        return await ampFetch({
            route: 'api/auth/force-change-password',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    session,
                    username,
                    new_password
                })
            }
        });
    },
    /**
     * @typedef ForgotPasswordResponse
     * @property {'SUCCESS'|'INVALID_PARAMETER'|'LIMIT_EXCEEDED'|'NOT_AUTHORIZED'|'FAILURE'} status
     */
    /**
     * @param {string} username
     * @returns {Promise<ForgotPasswordResponse>}
     */
    forgotPassword: async (username) => {
        return await ampFetch({
            route: 'api/auth/forgot-password',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    username
                })
            }
        });
    },
    /**
     * @typedef ChangePasswordResponse
     * @property {'SUCCESS'|'CODE_MISMATCH'|'EXPIRED_CODE'|'INVALID_PARAMETER'|'NOT_AUTHORIZED'|'TOO_MANY_FAILED_ATTEMPTS'|'FAILURE'} status
     */
    /**
     * @param {string} username
     * @param {string} confirmation_code
     * @param {string} new_password
     * @returns {Promise<*>}
     */
    confirmForgotPassword: async (
        username,
        confirmation_code,
        new_password
    ) => {
        return await ampFetch({
            route: 'api/auth/confirm-forgot-password',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    username,
                    confirmation_code,
                    new_password
                })
            }
        });
    },
    /**
     * @typedef {object} SignUpUserResponse
     * @property {'SUCCESS'|'USERNAME_EXISTS'|'INVALID_PASSWORD'|'FAILURE'} status
     * @property {UserAuthData} success
     */
    /**
     * @param {string} username
     * @param {string} password
     * @returns {Promise<SignUpUserResponse>}
     */
    signUp: async (username, password) => {
        return await ampFetch({
            route: 'api/auth/sign-up',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    username,
                    password
                })
            }
        });
    },
    /**
     * @typedef {object} SignInUserResponse
     * @property {'SUCCESS'|'NEW_PASSWORD_REQUIRED'|'PASSWORD_RESET_REQUIRED'|'USER_NOT_CONFIRMED'|'FAILURE'} status
     * @property {object} success
     * @property {string} success.token
     * @property {string} success.expires_at
     * @property {object} new_password_required
     * @property {string} new_password_required.session
     */
    /**
     * @param {string} username
     * @param {string} password
     * @returns {Promise<SignInUserResponse>}
     */
    signIn: async (username, password) => {
        return await ampFetch({
            route: 'api/auth/sign-in',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    username,
                    password
                })
            }
        });
    },
    /**
     * @typedef {object} SignOutResponse
     * @property {'SUCCESS'|'FAILURE'} status
     */
    /**
     * @param {string} access_token
     * @returns {Promise<SignOutResponse>}
     */
    signOut: async (access_token) => {
        return await ampFetch({
            route: 'api/auth/sign-out',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    access_token
                })
            }
        });
    },
    /**
     * @typedef {object} ConfirmUserResponse
     * @property {'SUCCESS'|'CODE_MISMATCH'|'FAILURE'} status
     * @property {UserAuthData} success
     */
    /**
     * @param {string} username
     * @param {string} confirmation_code
     * @returns {Promise<ConfirmUserResponse>}
     */
    confirmUser: async (username, confirmation_code) => {
        return await ampFetch({
            route: 'api/auth/confirm-user',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    username,
                    confirmation_code
                })
            }
        });
    },
    /**
     * @typedef {object} ResendConfirmationCodeResponse
     * @property {'SUCCESS'|'FAILURE'} status
     */
    /**
     * @param {string} username
     * @returns {Promise<ResendConfirmationCodeResponse>}
     */
    resendConfirmationCode: async (username) => {
        return await ampFetch({
            route: 'api/auth/resend-confirmation-code',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    username
                })
            }
        });
    },
    /**
     * @param {string} email
     * @param {string} account_name
     * @param {string} [name]
     * @param {string} [phone]
     * @param {string} [zip_code]
     * @param {string} [birthdate]
     * @returns {Promise<UserResponse>}
     */
    createUserAndAccount: async (
        email,
        account_name,
        name,
        phone,
        zip_code,
        birthdate
    ) => {
        return await ampFetch({
            route: 'api/create-user-and-account',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    email,
                    account_name,
                    name,
                    phone,
                    zip_code,
                    birthdate
                })
            }
        });
    },
    /**
     * @returns {Promise<UserResponse>}
     */
    getCurrentUser: async () => {
        return await ampFetch({
            route: 'api-user/get-current-user',
            options: {
                method: 'GET'
            }
        });
    },
    /**
     * @typedef {object} ValidateDeleteUserResponse
     * @property boolean can_delete
     * @property {'USER_NOT_FOUND'|'USER_HAS_ACTIVE_SUBSCRIPTION'} fail_reason
     */
    /**
     * @returns {Promise<ValidateDeleteUserResponse>}
     */
    validateDelete: async () => {
        return await ampFetch({
            route: 'api-user/user/validate-delete',
            options: {
                method: 'POST'
            }
        });
    },
    delete: async () => {
        return await ampFetch({
            route: 'api-user/user/delete',
            options: {
                method: 'POST'
            }
        });
    },
    /**
     * @param name
     * @param phone
     * @param zip_code
     * @param birthday
     * @param promotion_texts
     * @param promotion_emails
     * @param account_texts
     * @param account_emails
     *
     * @returns {Promise<UserResponse>}
     */
    updateUser: async ({
        name,
        phone,
        zip_code,
        birthday,
        promotion_texts,
        promotion_emails,
        account_texts,
        account_emails
    }) => {
        return await ampFetch({
            route: 'api-user/update-user',
            options: {
                method: 'POST',
                body: JSON.stringify({
                    name,
                    phone,
                    zip_code,
                    birthday,
                    promotion_texts,
                    promotion_emails,
                    account_texts,
                    account_emails
                })
            }
        });
    }
};

export default user;
