import { isBrowser } from '~/utilities/helpers';

/**
 * @param {string} token
 * @param {string} expires_at
 *
 * @returns void
 */
export const saveToken = ({ token, expires_at }) => {
    if (isBrowser()) {
        localStorage.setItem('ampToken', token);
        localStorage.setItem('ampExpires', expires_at);
    }
};

/**
 * @returns {boolean}
 */
export const isTokenExpired = () => {
    const expires = getTokenExpiration();

    if (expires) {
        const expiredDate = new Date(expires);
        const nowDate = new Date();

        return expiredDate < nowDate;
    }

    return true;
};

/**
 * @returns {string|null}
 */
export const getToken = () => {
    if (isBrowser()) {
        if (!isTokenExpired()) {
            return localStorage.getItem('ampToken');
        } else {
            localStorage.removeItem('ampToken');
            localStorage.removeItem('ampExpires');
        }
    }
    return null;
};

/**
 * @returns {string|null}
 */
export const getTokenExpiration = () => {
    if (isBrowser()) {
        return localStorage.getItem('ampExpires');
    }
    return null;
};

/**
 * @returns {void}
 */
export const resetToken = () => {
    if (isBrowser()) {
        localStorage.removeItem('ampToken');
        localStorage.removeItem('ampExpires');
    }
};

/**
 * @returns {boolean|""|null}
 */
export const isLoggedIn = () => {
    if (isBrowser()) {
        return getToken() && !isTokenExpired();
    }
    return false;
};

/**
 * @param vehicleId
 *
 * @returns {void}
 */
export const setDefaultVehicleId = (vehicleId) => {
    if (isBrowser() && vehicleId) {
        localStorage.setItem('defaultVehicleId', vehicleId);
    }
};

/**
 * @returns {string|null}
 */
export const getDefaultVehicleId = () => {
    if (isBrowser()) {
        return localStorage.getItem('defaultVehicleId');
    }
    return null;
};

export const getErrorMessage = (status) => {
    switch (status) {
        case 'SUCCESS':
            return null;
        case 'USERNAME_EXISTS':
            return 'There is already an account associated with that email address.';
        case 'INVALID_PARAMETER':
            return 'Please ensure all fields are valid.';
        case 'LIMIT_EXCEEDED':
            return 'Number of forgot password attempts has been exceeded.';
        case 'NOT_AUTHORIZED':
            return 'Not authorized to perform this function.';
        case 'INVALID_PASSWORD':
            return 'Please enter a valid password.';
        case 'CODE_MISMATCH':
            return 'The provided confirmation code does not match.';
        case 'EXPIRED_CODE':
            return 'The provided confirmation code has expired. Please request a new one.';
        case 'TOO_MANY_FAILED_ATTEMPTS':
            return 'Too many failed attempts, please try again later.';
        case 'FAILURE':
            return 'An error has occurred processing your submission.';
        case 'CANNOT_FIND_VEHICLE':
            return 'Vehicle not found.';
        case 'VEHICLE_SUBSCRIPTION_NOT_FOUND':
            return 'This vehicle does not have a current subscription. You will need to create a new subscription.';
        case 'USER_ALREADY_ON_ACCOUNT':
            return 'This user is already associated with this garage.';
        case 'USER_NOT_ON_ACCOUNT':
            return 'This user is not associated with this garage.';
        case 'ARCHIVED':
            return 'This user account has been archived.';
        case 'COMPROMISED':
            return 'This user account has been compromised.';
        default:
            return 'An unknown error has occurred.';
    }
};

export const emailRegex =
    /^[_a-z0-9-]+(\.[_a-z0-9-]+)*(\+[a-z0-9-]+)?@[a-z0-9-]+(\.[a-z0-9-]+)*$/i;
