import subscriptions from '~/account/api/subscriptions';

import { filter, map, concat } from 'lodash';

const validateSubscriptionPromoCode = async (promoCode, subscriptionItems) => {
    let promoCodeId = null;
    let successItems = [];
    for (const item of subscriptionItems) {
        const response = await subscriptions.validateSubscriptionPromoCode(
            item.price_id,
            promoCode
        );
        if (response.success) {
            successItems.push(item);
            promoCodeId = response.data.promo_code_data.promo_code_id;
        }
    }

    return { successItems, promoCodeId };
};

export const validatePromoCode = async (promoCode, customerId, items) => {
    let finalSuccessItems = [];
    const subscriptionItems = filter(items, {
        productType: 'subscription'
    });
    let promoCodeId = null;

    if (subscriptionItems.length) {
        const { successItems, promoCodeId: codeId } =
            await validateSubscriptionPromoCode(promoCode, subscriptionItems);
        if (successItems.length && codeId) {
            promoCodeId = codeId;
            finalSuccessItems = concat(
                finalSuccessItems,
                map(successItems, (item) => ({
                    ...item,
                    promoCodeId
                }))
            );
        }
    }

    return { successItems: finalSuccessItems, promoCodeId };
};
