import { useStaticQuery, graphql } from 'gatsby';
import { map } from 'lodash';

const useLocations = () => {
    const { allYextLocation } = useStaticQuery(graphql`
        query LocationPickerQuery {
            allYextLocation(filter: { ampID: { ne: null } }) {
                edges {
                    node {
                        id
                        name
                        address {
                            line1
                            city
                            region
                            postalCode
                        }
                        folder {
                            name
                        }
                        yextRoutableCoordinate {
                            latitude
                            longitude
                        }
                        hours {
                            monday {
                                openIntervals {
                                    end
                                }
                            }
                            tuesday {
                                openIntervals {
                                    end
                                }
                            }
                            wednesday {
                                openIntervals {
                                    end
                                }
                            }
                            thursday {
                                openIntervals {
                                    end
                                }
                            }
                            friday {
                                openIntervals {
                                    end
                                }
                            }
                            saturday {
                                openIntervals {
                                    end
                                }
                            }
                            sunday {
                                openIntervals {
                                    end
                                }
                            }
                        }
                        mainPhone
                        ampID
                        c_baseURL
                        priceGroup
                    }
                }
            }
        }
    `);
    return map(allYextLocation.edges, 'node');
};

export default useLocations;
